import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { getCopayFields } from './helpers/CopayHelpers/getCopayFields';
import { getCopays } from './helpers/CopayHelpers/getCopays';

export function nursingCopays() {
  return {
    label: 'Skilled Nursing Copay',
    isExpanded: !getShouldBeDisabled(this.eligibility.skilledNursingCopayInfos),
    isDisabled: getShouldBeDisabled(this.eligibility.skilledNursingCopayInfos),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBodyTable/AccordionBodyTable'
      ),
    }),
    bodyProps: {
      fields: getCopayFields(),
      data: getCopays(this.eligibility.skilledNursingCopayInfos),
    },
  };
}
