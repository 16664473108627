import { benefitInfoNoServiceType } from './benefitConstants';

/**
 * Takes a map of arrays with service codes as the key
 * and turns them into easily displayable accordion data
 * @param {*} ebiMapObj
 * @returns an array of objects to display as collapsable elements
 */
export function createListFromEbisMap(ebiMapObj) {
  var accordionArray = [];
  if (ebiMapObj.size > 0) {
    //get expand option setting
    var expandAll = false;
    var profile = this.profiles[0];
    if (profile && profile.expandOptions) {
      var insuranceOption = profile.expandOptions.filter(
        f => f.name === 'Insurance detail'
      );
      if (insuranceOption && insuranceOption[0]) {
        if (insuranceOption[0].expandAll === 'true') {
          expandAll = true;
        }
      }
    }
    for (const ebi of ebiMapObj.values()) {
      var accLabel = '';
      var showByDefault = false;
      var sortOrder = 0;
      if (ebi.code == benefitInfoNoServiceType) {
        accLabel = benefitInfoNoServiceType;
      } else {
        var serviceType = this.serviceTypes.find(el => el.code === ebi.code);
        if (serviceType) {
          accLabel = serviceType.description;
          showByDefault = serviceType.showByDefault;
          sortOrder = serviceType.sortOrder;
        }
      }
      if (accLabel) {
        accordionArray.push({
          label: accLabel,
          showByDefault: expandAll || showByDefault,
          data: ebi.data,
          sortOrder: sortOrder,
        });
      }
    }

    //order by showByDefault as first then sort by sort order
    accordionArray
      .sort((a, b) => b.showByDefault - a.showByDefault)
      .sort(sortBySortOrder());
  }
  return accordionArray;
}

function sortBySortOrder() {
  return function(a, b) {
    // equal items sort equally
    if (a.sortOrder === b.sortOrder) {
      return 0;
    }
    // nulls sort after anything else
    else if (a.sortOrder === 0) {
      return 1;
    } else if (b.sortOrder === 0) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    return a < b ? -1 : 1;
  };
}
