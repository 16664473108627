import {
  CHECK_PAYOR_IS_MAPPED,
  SET_PAYOR_IS_MAPPED,
} from 'src/store/actions/types';
import { mapActions, mapState } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
//TODO: Refactor LabelAutoComplete to use the new structure
//and or potentially to use a completely new system
import LabelAutoComplete from 'src/components/controls/LabelAndAutoCompleteControl';

library.add(faMapMarkedAlt);

export default {
  props: {
    payor: {
      type: String,
      required: true,
    },
    policy: String,
    arrayIndex: Number,
    insuranceType: Number,
    isMapped: Boolean,
  },
  components: {
    LabelAutoComplete,
  },
  computed: {
    ...mapState({
      allPayors: state => state.applicant.payors,
      insurance: state => state.applicant.data.insurance,
    }),
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  data() {
    return {
      payorModal: false,
      mappedPayor: null,
    };
  },
  methods: {
    ...mapActions([SET_PAYOR_IS_MAPPED, CHECK_PAYOR_IS_MAPPED]),
    mapPayor() {
      if (this.mappedPayor != null) {
        this[SET_PAYOR_IS_MAPPED]({
          payorId: this.mappedPayor.payorId,
          companyPayorName: this.payor,
          arrayIndex: this.arrayIndex,
          insuranceType: this.insuranceType,
        });
      }
    },
    showPayorModal() {
      this.$refs['payor-modal'].show();
    },
    selected(data) {
      this.mappedPayor = data;
    },
  },
  created() {
    //TODO: Currently each payor name is being checked individually
    //ideally should refactor api to run in batch
    //this check would then be moved to the parent
    if (this.insurance && this.insurance[this.arrayIndex].mappedStatus == 'success') {
      //already loaded, don't need to recheck
    }
    else {
      this[CHECK_PAYOR_IS_MAPPED]({
        payorName: this.payor,
        arrayIndex: this.arrayIndex,
        insuranceType: this.insuranceType,
      });
    }
  },
};
