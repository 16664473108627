import Accordion from 'src/components/FullPatientData/PatientWidgetBody/Accordion/Accordion';
import clone from 'clone';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Accordion,
  },
  data() {
    return {
      accordions: {},
    };
  },
  created() {
    this.accordions = clone(this.data);
  },
  methods: {
    toggle(accordionIndex) {
      return () => {
        for (const [key] of Object.entries(this.accordions)) {
          if (key === accordionIndex) {
            this.accordions[accordionIndex].isExpanded = !this.accordions[
              accordionIndex
            ].isExpanded;
          } else {
            this.accordions[key].isExpanded = false;
          }
        }
      };
    },
  },
};
