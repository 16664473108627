import { mapState, mapGetters } from 'vuex';
import AccordionGroup from 'src/components/FullPatientData/PatientWidgetBody/AccordionGroup/AccordionGroup';
import ExpandAllAccordion from 'src/components/FullPatientData/PatientWidgetBody/ExpandAllAccordion/ExpandAllAccordion';
import { generalPolicy } from './computed/generalPolicy';
import { subscriberData } from './computed/subscriberData';
import { dependentData } from './computed/dependentData';
import { hospice } from './computed/hospice';
import { hospitalCopays } from './computed/hospitalCopays';
import { nursingCopays } from './computed/nursingCopays';
import { payorAddress } from './computed/payorAddress';
import { benefitInformation } from './computed/benefitInformation';

export default {
  components: {
    AccordionGroup,
    ExpandAllAccordion,
  },
  props: {
    eligibility: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      serviceTypes: state => state.applicant.eligibilities.serviceTypes,
      profiles: state => state.profiles.data,
    }),
    ...mapGetters(['applicant']),
    //Map the given data so we can easily loop over and display it
    displayData: {
      get: function() {
        //each item in the array will contian data for one accordian
        return {
          generalPolicy: generalPolicy.call(this),
          subData: subscriberData.call(this),
          dependentData: dependentData.call(this),
          hospice: hospice.call(this),
          hospitalCopays: hospitalCopays.call(this),
          nursingCopay: nursingCopays.call(this),
          payorAddr: payorAddress.call(this),
          benefitInfos: benefitInformation.call(this),
        };
      },
    },
  },
};
