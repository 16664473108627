import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { buildAddress } from 'src/utils/buildAddress';

export function payorAddress() {
  return {
    label: 'Payor Address',
    isExpanded: !getShouldBeDisabled(
      this.eligibility.payorAddress1,
      this.eligibility.payorCity,
      this.eligibility.payorState,
      this.eligibility.payorZip,
      this.eligibility.payorAddress2
    ),
    isDisabled: getShouldBeDisabled(
      this.eligibility.payorAddress1,
      this.eligibility.payorCity,
      this.eligibility.payorState,
      this.eligibility.payorZip,
      this.eligibility.payorAddress2
    ),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        combined: {
          id: 'combined-address',
          fields: [
            {
              label: 'Payor Address',
              value: buildAddress({
                address1: this.eligibility.payorAddress1,
                city: this.eligibility.payorCity,
                state: this.eligibility.payorState,
                zip: this.eligibility.payorZip,
                address2: this.eligibility.payorAddress2,
              }),
            },
          ],
        },
      },
    },
  };
}
