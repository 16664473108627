import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { dateStr } from 'src/utils/dateStr';
import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function subscriberData() {
  return {
    label: 'Subscriber Data',
    isExpanded: !getShouldBeDisabled(
      this.eligibility.receivedFirstName,
      this.eligibility.receivedMiddleName,
      this.eligibility.receivedLastName,
      this.eligibility.receivedDOB,
      this.eligibility.receivedSSN,
      this.eligibility.receivedGender,
      this.eligibility.receivedCity,
      this.eligibility.receivedState,
      this.eligibility.receivedZip
    ),
    isDisabled: getShouldBeDisabled(
      this.eligibility.receivedFirstName,
      this.eligibility.receivedMiddleName,
      this.eligibility.receivedLastName,
      this.eligibility.receivedDOB,
      this.eligibility.receivedSSN,
      this.eligibility.receivedGender,
      this.eligibility.receivedCity,
      this.eligibility.receivedState,
      this.eligibility.receivedZip
    ),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        left: {
          id: 'left-subscriber',
          fields: stripEmptyValues([
            {
              label: 'Sub. First Name',
              value: this.eligibility.receivedFirstName,
              mismatchValue: this.applicant.firstname,
              isMismatch: true,
            },
            {
              label: 'Sub. Middle Name',
              value: this.eligibility.receivedMiddleName,
              mismatchValue: this.applicant.middlename,
              isMismatch: true,
            },
            {
              label: 'Sub. Last Name',
              value: this.eligibility.receivedLastName,
              mismatchValue: this.applicant.lastname,
              isMismatch: true,
            },
            {
              label: 'Sub. Suffix',
              value: this.eligibility.receivedSuffix,
            },
            {
              label: 'Subscriber DOB',
              value: dateStr(this.eligibility.receivedDOB),
              mismatchValue: dateStr(this.applicant.dob),
              isMismatch: true,
            },
            {
              label: 'Subscriber SSN',
              value: this.eligibility.receivedSSN,
              mismatchValue: this.applicant.ssn,
              isMismatch: true,
            },
            {
              label: 'Sub. Gender',
              value: this.eligibility.receivedGender,
              mismatchValue: this.applicant.gender,
              isMismatch: true,
            },
          ]),
        },
        right: {
          id: 'right-subscriber',
          fields: stripEmptyValues([
            {
              label: 'Address 1',
              value: this.eligibility.receivedAddress?.address1,
              mismatchValue: this.applicant.homeAddress?.address1,
              isMismatch: true,
            },
            {
              label: 'Address 2',
              value: this.eligibility.receivedAddress?.address2,
              mismatchValue: this.applicant.homeAddress?.address2,
              isMismatch: true,
            },
            {
              label: 'City',
              value: this.eligibility.receivedAddress?.city,
              mismatchValue: this.applicant.homeAddress?.city,
              isMismatch: true,
            },
            {
              label: 'State',
              value: this.eligibility.receivedAddress?.state,
              mismatchValue: this.applicant.homeAddress?.state,
              isMismatch: true,
            },
            {
              label: 'Zip',
              value: this.eligibility.receivedAddress?.zip,
              mismatchValue: this.applicant.homeAddress?.zip,
              isMismatch: true,
            },
          ]),
        },
      },
    },
  };
}
