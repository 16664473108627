import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { getBenefitInformation } from './helpers/BenefitInfoHelpers/getBenefitInformation';

export function benefitInformation() {
  return {
    label: 'Benefit Information',
    isExpanded: !getShouldBeDisabled(this.eligibility.benefitInfoes),
    isDisabled: getShouldBeDisabled(this.eligibility.benefitInfoes),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/MultiLevelAccordionBody/MultiLevelAccordionBody'
      ),
    }),
    bodyProps: {
      data: getBenefitInformation.call(this, this.eligibility.benefitInfoes),
    },
  };
}
