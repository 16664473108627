export function isMedicareSecondaryPayor(eligibility) {
  if (eligibility.benefitInfoes) {
    for (let i = 0; i < eligibility.benefitInfoes.length; i++) {
      var benefit = eligibility.benefitInfoes[i];
      if (
        (benefit.infoType == '1' ||
          benefit.infoType == '6' ||
          benefit.infoType == 'R') &&
        (benefit.insuranceType == '12' ||
          benefit.insuranceType == '13' ||
          benefit.insuranceType == '14' ||
          benefit.insuranceType == '15' ||
          benefit.insuranceType == '16' ||
          benefit.insuranceType == '41' ||
          benefit.insuranceType == '42' ||
          benefit.insuranceType == '43' ||
          benefit.insuranceType == '47')
      ) {
        console.log(
          'EligibilityId: ' +
            eligibility.eligibilityId +
            ' infotype: ' +
            benefit.infoType +
            ' insuranceType: ' +
            benefit.insuranceType +
            ' relatedEntityType: ' +
            benefit.relatedEntityType +
            ' relatedEntityName: ' +
            benefit.relatedEntityName
        );
        return 'MSP';
      } else if (
        benefit.infoType == 'R' &&
        benefit.relatedEntityType == 'PRP' &&
        benefit.relatedEntityName != null &&
        benefit.relatedEntityName.includes('Medicare')
      ) {
        console.log(
          'EligibilityId: ' +
            eligibility.eligibilityId +
            ' infotype: ' +
            benefit.infoType +
            ' insuranceType: ' +
            benefit.insuranceType +
            ' relatedEntityType: ' +
            benefit.relatedEntityType +
            ' relatedEntityName: ' +
            benefit.relatedEntityName
        );
        return 'MSP';
      }
    }
  }
  return null;
}
