import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function dependentData() {
  return {
    label: 'Dependent Data',
    isExpanded: !getShouldBeDisabled(
      this.eligibility.depFirstName,
      this.eligibility.depMiddleName,
      this.eligibility.depLastName,
      this.eligibility.depDob,
      this.eligibility.depSSN,
      this.eligibility.depCity,
      this.eligibility.depState,
      this.eligibility.depZip,
      this.eligibility.depGender
    ),
    isDisabled: getShouldBeDisabled(
      this.eligibility.depFirstName,
      this.eligibility.depMiddleName,
      this.eligibility.depLastName,
      this.eligibility.depDob,
      this.eligibility.depSSN,
      this.eligibility.depCity,
      this.eligibility.depState,
      this.eligibility.depZip,
      this.eligibility.depGender
    ),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        left: {
          id: 'left-subscriber',
          fields: stripEmptyValues([
            {
              label: 'Dep. First Name',
              value: this.eligibility.depFirstName,
              mismatchValue: this.applicant.depFirstNameMismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. Middle Name',
              value: this.eligibility.depMiddleName,
              mismatchValue: this.applicant.depMiddleNameMismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. Last Name',
              value: this.eligibility.depLastName,
              mismatchValue: this.applicant.depLastNameMismatch,
              isMismatch: true,
            },
            {
              label: 'Dependent DOB',
              value: this.dateStr(this.eligibility.depDOB),
              mismatchValue: this.applicant.depDOBMismatch,
              isMismatch: true,
            },
            {
              label: 'Dependent SSN',
              value: this.eligibility.depFirstName,
              mismatchValue: this.applicant.depSSNMismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. Gender',
              value: this.eligibility.depFirstName,
              mismatchValue: this.applicant.depGenderMismatch,
              isMismatch: true,
            },
          ]),
        },
        right: {
          id: 'right-subscriber',
          fields: stripEmptyValues([
            {
              label: 'Dep. Address 1',
              value: this.eligibility.depAddress1,
              mismatchValue: this.applicant.depAddress1Mismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. Address 2',
              value: this.eligibility.depAddress2,
              mismatchValue: this.applicant.depAddress2Mismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. City',
              value: this.eligibility.depCity,
              mismatchValue: this.applicant.depCityMismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. State',
              value: this.eligibility.depState,
              mismatchValue: this.applicant.depStateMismatch,
              isMismatch: true,
            },
            {
              label: 'Dep. Zip',
              value: this.eligibility.depZip,
              mismatchValue: this.applicant.depZipMismatch,
              isMismatch: true,
            },
          ]),
        },
      },
    },
  };
}
