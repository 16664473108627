import { getDescriptions } from './getDescriptions';
import { addToArray } from './addToArray';

/**
 * Takes a benefit information object and parses its data
 * into values that can be displayed.
 * @param {*} benefitInfo
 * @param {*} previousDesc
 * @returns
 */
export function getEbiObj(benefitInfo, previousDesc) {
  var ebiArray = [];
  const infoType = benefitInfo.infoType;
  var desc = getDescriptions(benefitInfo);
  var fullDescLabel = benefitInfo.infoTypeDescription;
  var fullDescValue = '';

  if (benefitInfo.timePeriod) {
    fullDescLabel += ' (' + benefitInfo.timePeriod + ')';
  }
  if (benefitInfo.inPlanNetworkDescription) {
    fullDescLabel += ' ' + benefitInfo.inPlanNetworkDescription;
  }
  if (benefitInfo.amount || benefitInfo.amount === 0) {
    fullDescValue = '$' + benefitInfo.amount;
  }
  if (benefitInfo.quantity || benefitInfo.quantityDescription) {
    fullDescValue +=
      ' ' + benefitInfo.quantity + benefitInfo.quantityDescription;
  }
  if (benefitInfo.percentage || benefitInfo.percentage === 0) {
    fullDescValue += benefitInfo.percentage * 100.0;
  }
  if (benefitInfo.authorizationCertificateRequiredDescription) {
    fullDescValue += benefitInfo.authorizationCertificateRequiredDescription;
  }
  //Splits the contact numbers up and if it was empty string the filter returns []
  //instead of [""]
  if (benefitInfo.relatedEntityContactNumbers) {
    for (const contactNumber in benefitInfo.relatedEntityContactNumbers
      .split('|')
      .filter(o => o)) {
      if (infoType === 'U' || infoType == 'R') {
        fullDescValue += ' ' + contactNumber;
      }
    }
  }

  addToArray(
    ebiArray,
    'Insurance Type',
    benefitInfo.insuranceTypeDescription,
    desc.insDesc && desc.insDesc != previousDesc.insDesc
  );
  addToArray(
    ebiArray,
    'Insurance Plan',
    benefitInfo.planCoverageDescription,
    desc.planDesc && desc.planDesc != previousDesc.planDesc
  );
  addToArray(
    ebiArray,
    'Coverage Level',
    benefitInfo.coverageLevelDescription,
    desc.covDesc && desc.covDesc != previousDesc.covDesc
  );
  addToArray(ebiArray, fullDescLabel, fullDescValue, true);
  addToArray(
    ebiArray,
    'Todo (finish): Procedure Code',
    benefitInfo.procedureCode,
    true
  );
  addToArray(ebiArray, 'Identifications', benefitInfo.identifications, true);
  addToArray(
    ebiArray,
    'Related Entity Name',
    benefitInfo.relatedEntityName,
    true
  );
  addToArray(
    ebiArray,
    'Todo (finish): Related Entity Contact Numbers',
    benefitInfo.relatedEntityContactNumbers,
    infoType && infoType != 'U' && infoType != 'R'
  );
  addToArray(ebiArray, 'Messages', benefitInfo.messages, true);

  return ebiArray;
}
