import { isDeductableInPlan } from './isDeductableInPlan';

export function getDeductableStr(deductibles) {
  var message = '';
  deductibles.forEach(
    d =>
      (message +=
        d.description +
        ' Remaining ' +
        isDeductableInPlan(d.inPlanNetwork) +
        d.amount +
        '\n')
  );
  return message;
}
