import { mapState, mapActions, mapGetters } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileInvoice, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DetailsModalBody from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/DetailsModalBody';
import { dateTimeStr } from 'src/utils/dateTimeStr';

import {
  GET_HISTORY_INITIAL_DATA,
  GET_HISTORY_ELIGIBILITY,
  GET_HISTORY_PDF,
} from 'src/store/actions/types';

library.add(faFileInvoice);
library.add(faFilePdf);

export default {
  components: {
    DetailsModalBody,
  },
  computed: {
    ...mapGetters(['applicant']),
    ...mapState({
      history: state => state.applicant.history.data,
    }),
  },

  methods: {
    ...mapActions([
      GET_HISTORY_INITIAL_DATA,
      GET_HISTORY_ELIGIBILITY,
      GET_HISTORY_PDF,
    ]),
    async details(data, button) {
      this.detailsModal.title = data.payor + ' Information';
      if (!data.eligibility) {
        await this[GET_HISTORY_ELIGIBILITY]({
          eligibilityId: data.eligibilityId,
        });
      }
      this.detailsModal.content = data.eligibility;
      this.$root.$emit('bv::show::modal', this.detailsModal.id, button);
    },
    resetDetailsModal() {
      this.detailsModal.title = '';
      this.detailsModal.content = {};
    },
    getDateTimeStr(dtStr) {
      return dateTimeStr(dtStr);
    },
    getCreatedByMapping(createdBy) {
      if (createdBy === 'Advanced Verifier') {
        return 'Verify';
      }
      if (createdBy === 'Client') {
        return 'Verify';
      }
      if (createdBy === 'Eligibility Check') {
        return 'Verify';
      }
      if (createdBy === 'MBI') {
        return 'MBI';
      }
      if (createdBy === 'Realtime Insurance Finder') {
        return 'Discover';
      }
      if (createdBy === 'Recall') {
        return 'Verify'
      }
      if (createdBy === 'Retro Check') {
        return 'Retro Check';
      }
      else {
        return 'Verify';
      }
    },
    //TODO: remove this and same code from DiscoveryTable and add to state
    async print(data) {
      if (!this.printing) {
        this.printing = true;
        if (!data.pdf) {
          await this[GET_HISTORY_PDF](data.eligibilityId);
        }
        this.download('eligibility.pdf', data.pdf);
        this.printing = false;
      }
    },
    download(filename, text) {
      var pom = document.createElement('a');
      pom.setAttribute(
        'href',
        'data:application/pdf;base64,' + encodeURIComponent(text)
      );
      pom.setAttribute('download', filename);

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    },
  },

  data() {
    return {
      sortBy: 'datetime',
      sortDesc: true,
      fields: [
        { key: 'datetime', sortable: true },
        { key: 'payor', sortable: true },
        { key: 'buttons', sortable: false },
      ],
      detailsModal: {
        id: 'history-details-modal',
        title: '',
        content: {},
      },
      printing: false,
    };
  },
  created() {
    this[GET_HISTORY_INITIAL_DATA]();
  },
};
