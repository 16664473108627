export default {
  props: ['message', 'icon', 'typeClass', 'messageSize'],
  methods: {
    getClass() {
      if (this.typeClass === 'error') {
        return 'error-container';
      } else if (this.typeClass === 'warning') {
        return 'warning-container';
      }
      return '';
    },
  },
};
