export function isDeductableInPlan(inPlan) {
  var plan = '';
  if (inPlan) {
    if (inPlan == 'Y') {
      plan = ' (IN) ';
    } else if (inPlan == 'N') {
      plan = ' (OON) ';
    }
  }
  return plan;
}
