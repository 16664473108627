import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SuggestionHeader from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoverySuggestion/SuggestionHeader/SuggestionHeader';

library.add(faInfoCircle);

export default {
  components: {
    SuggestionHeader,
  },
};
