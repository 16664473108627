/**
 * builds and returns the address string depending on which fields are available
 * @param {object}
 * @returns {string}
 */
export const buildAddress = ({
  address1,
  city,
  state,
  zip,
  address2 = null,
}) => {
  const parts = [];
  if (address1 && String(address1).trim().length) {
    parts.push(String(address1).trim());
  }
  if (address2 && String(address2).trim().length) {
    parts.push(String(address2).trim());
  }
  if (city && String(city).trim().length) {
    parts.push(String(city).trim());
  }
  if (state && String(state).trim().length) {
    parts.push(String(state).trim());
  }
  let address = parts.join(', ');
  if (zip && String(zip).trim().length) {
    return `${address} ${String(zip).trim()}`;
  }
  return address;
};
