import appService from 'src/pl.service';

export default {
  components: {
  },
  props: {
    eligibilityId: {
      type: Number,
      required: true,
    },
    payor: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      fileContents: null,
      hasError: false,
      visible: true,
      unique271ModalId: 'raw271-modal' + this.eligibilityId,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    displayThisModal() {
      this.fileContents = 'Loading...';
      this.loadContents();
      this.$bvModal.show(this.unique271ModalId);
    },
    loadContents() {
      appService
        .getEligibility271(this.eligibilityId)
        .then(data => {
          this.fileContents = data;
          if (!data) {
            setError(this);
          }
        })
        .catch(function(error) {
          console.error(error);
          setError(this);
        });

      function setError(comp) {
        comp.fileContents = 'Unable to load file';
        comp.hasError = true;
      }
    },
  },
};
