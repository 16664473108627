/**
 * Takes two items and diplays them as a range if both exist
 * or if only one exists, that one is returned.
 * @param {*} item1
 * @param {*} item2
 * @returns
 */

export const displayRange = (item1, item2) => {
  var rangeString = '';
  if (item1 && item2) {
    rangeString = item1 + ' - ' + item2;
  } else if (item1) {
    rangeString = item1;
  } else if (item2) {
    rangeString = item2;
  }
  return rangeString;
};
