export function getCompanyStatuses(hospice, qmb, rr, dod, deductibles) {
  var statuses = [];
  if (hospice) {
    statuses.push({ name: 'Hospice', class: 'error' });
  }
  if (qmb && qmb === 'Y') {
    statuses.push({ name: 'QMB', class: 'teal' });
  }
  if (rr && rr === 'Y') {
    statuses.push({ name: 'RR', class: 'font-weight-bold' });
  }

  if (dod) {
    statuses.push({ name: 'Deceased', class: 'error', icon: 'ban' });
  }

  if (deductibles) {
    var partA = deductibles.find(d => d.description == 'Part A');
    if (deductibles.find(d => d.description == 'Part B') && partA) {
      statuses.push({ name: 'MB', class: '' });
    } else if (partA) {
      statuses.push({ name: 'MA', class: '' });
    }
  }

  return statuses;
}
