export function getStatusInfo(status, rejectReasonDescription) {
  var statusObject = {
    name: 'Review',
    icon: 'question-circle',
    type: 'review',
    message: '',
  };
  if (status == 'Active Coverage') {
    statusObject = { name: 'Active', icon: 'check', type: 'success' };
  } else if (status == 'Inactive Coverage' || status == 'Inactive') {
    statusObject = {
      name: status,
      icon: 'arrow-down',
      type: 'warning',
    };
  } else if (status == 'Error') {
    statusObject = {
      name: 'Error',
      icon: 'exclamation-circle',
      type: 'error',
      message: rejectReasonDescription,
    };
  }
  return statusObject;
}
