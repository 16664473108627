export default {
  props: {
    displayValue: {},
    compareValue: {},
  },
  computed: {
    isDifferent: function() {
      if (this.displayValue && this.compareValue) {
        return (
          this.displayValue.toLowerCase() !== this.compareValue.toLowerCase()
        );
      }
      return this.displayValue !== this.compareValue;
    },
  },
};
