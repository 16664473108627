import { isPresent } from './helpers/isPresent';
import { displayRange } from 'src/utils/displayRange';
import { dateStr } from 'src/utils/dateStr';
import { getDeductableStr } from './helpers/getDeductableStr';
import { getConfidenceScoreInfo } from './helpers/getConfidenceScoreInfo';
import { getStatusInfo } from './helpers/getStatusInfo';
import { getCompanyStatuses } from './helpers/getCompanyStatuses';
import { isMedicareSecondaryPayor } from './helpers/isMedicareSecondaryPayor';
import { getInsurancePlanNames } from './helpers/getInsurancePlanNames';

/**
 * Formatted data to be displayed in the discovery table.
 * @returns
 */
export function tableData() {
  return this.eligibilities.map(el => ({
    data: el,
    eligibilityId: el.eligibilityId,
    ranking: el.hierarchy > 0 ? el.hierarchy : '',
    company_name: {
      name: el.payorName,
      statuses: getCompanyStatuses(
        el.hospiceNPI,
        el.qualifiedMedicareBeneficiary,
        el.railroadMedicareBeneficiary,
        el.dateOfDeath,
        el.deductibles
      ),
    },
    msp: {
      msp: isMedicareSecondaryPayor(el),
    },
    warnings: {
      value: el.managedCarePlan || el.medicareReplacementPayor ? '!' : '',
      class: 'error',
      tooltip: 'Managed Care or Replacement Payer',
    },
    confidence: getConfidenceScoreInfo(
      el.confidenceScore,
      el.confidenceScoreScore,
      el.status
    ),
    status: getStatusInfo(
      el.status,
      el.validations ? el.validations[0]?.rejectReasonDescription : ''
    ),
    isFlagged: el.incorrectHit,
    expandedInfo: {
      right: {
        id: 'right' + el.eligibilityId,
        fields: [
          { label: 'Status:', value: el.status },
          {
            label: 'Managed Care:',
            value: displayRange(el.managedCarePlan, el.managedCareSubscriberId),
          },
          {
            label: 'Duration:',
            value: displayRange(
              dateStr(el.policyStartDate),
              dateStr(el.policyEndDate)
            ),
          },
          {
            label: 'Sub #:',
            value: el.subscriberId,
            canCopy: true,
            has271: true,
            eligibilityId: el.eligibilityId,
            payorName: el.payorName,
            isMismatch: true,
            mismatchValue: el.subscriberIdSent,
          },
          {
            label: 'Prior Id #',
            value: el.priorIdentificationNumber,
          },
          {
            label: 'HIC:',
            value: el.healthInsuranceClaimNumber,
          },
          {
            label: 'Plan Network Id Number:',
            value: el.planNetworkIdentificationNumber,
            standout: true,
          },
          {
            label: 'Group #:',
            value: el.groupNumber,
            canCopy: true,
          },
          {
            label: 'Medicare Replacement Payor:',
            value: el.medicareReplacementPayor,
            standout: true,
          },
          {
            label: 'Managed Care Plan:',
            value: el.managedCarePlan,
            standout: true,
          },
        ],
      },
      left: {
        id: 'left' + el.eligibilityId,
        fields: [
          {
            label: 'Insurance Plan:',
            value: getInsurancePlanNames(el.benefitInfoes),
          },
          {
            label: 'Deductible:',
            value: getDeductableStr(el.deductibles),
            payorName: el.payorName,
          },
          {
            label: 'Deductible Status',
            value: el.deductibleManagement?.status,
          },
          {
            label: 'Name:',
            nameIsPresent: isPresent(
              el.receivedFirstName,
              el.receivedMiddleName,
              el.receivedLastName
            ),
            value: {
              firstName: this.applicant.firstname,
              recFirstName: el.receivedFirstName,
              middleName: this.applicant.middlename,
              recMiddleName: el.receivedMiddleName,
              lastName: this.applicant.lastname,
              recLastName: el.receivedLastName,
              suffix: el.receivedSuffix,
            },
            importCheckBox: true,
          },
          {
            label: 'Address:',
            addressIsPresent: isPresent(
              el.receivedAddress1,
              el.receivedCity,
              el.receivedState,
              el.receivedZip
            ),
            value: {
              street: this.applicant.homeAddress.address1,
              recStreet: el.receivedAddress1,
              city: this.applicant.homeAddress.city,
              recCity: el.receivedCity,
              state: this.applicant.homeAddress.state,
              recState: el.receivedState,
              zip: this.applicant.homeAddress.zip,
              recZip: el.receivedZip,
            },
            importCheckBox: true,
          },
          {
            label: 'DOB:',
            value: dateStr(el.receivedDOB),
            mismatchValue: dateStr(this.applicant.dob),
            importLabel: 'dob',
            isMismatch: true,
            importCheckBox: true,
          },
          {
            label: 'DOD:',
            value: dateStr(el.dateOfDeath),
            standout: true,
          },
          {
            label: 'SSN:',
            value: el.receivedSSN,
            importLabel: 'ssn',
            importCheckBox: true,
          },
          {
            label: 'Gender:',
            value: el.receivedGender,
            mismatchValue: this.applicant.gender,
            isMismatch: true,
          },
          {
            label: 'View 271',
            eligibilityId: el.eligibilityId,
            payorName: el.payorName,
          },
        ],
      },
    },
  }));
}
