import { confidenceScoreThresholdEligibility } from 'src/constants/insurance';

export function getConfidenceScoreInfo(
  confidenceScore,
  confidenceScoreScore,
  status
) {
  var confidenceScoreItems = { name: '', icon: '', type: '' };
  if (
    confidenceScore === 'Y' ||
    (confidenceScore !== 'R' &&
      confidenceScoreScore >= confidenceScoreThresholdEligibility)
  ) {
    confidenceScoreItems = { name: 'High', icon: 'arrow-up', type: 'success' };
  } else if (
    confidenceScore === 'R' ||
    (confidenceScore !== 'Y' &&
      confidenceScoreScore < confidenceScoreThresholdEligibility &&
      status != 'Not Found')
  ) {
    confidenceScoreItems = {
      name: 'Low',
      icon: 'arrow-down',
      type: 'warning',
    };
  }
  return confidenceScoreItems;
}
