import { zeroPad } from './zeroPad';

export const dateTimeStr = dateVal => {
  var dateString = '';
  if (dateVal) {
    var date = new Date(dateVal);
    dateString =
      zeroPad(date.getMonth() + 1) +
      '/' +
      zeroPad(date.getDate()) +
      '/' +
      date.getFullYear() +
      ' ' +
      zeroPad(date.getHours()) +
      ':' +
      zeroPad(date.getMinutes());
  }
  return dateString;
};
