import appService from 'src/pl.service';
import { mapState, mapActions, mapGetters } from 'vuex';
import DisplayIcon from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoveryTable/DisplayIcon/DisplayIcon';
import ExpandedData from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoveryTable/ExpandedData/ExpandedData';
import DetailsModalBody from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/DetailsModalBody';
import ErrorBanner from 'src/components/FullPatientData/PatientWidgetBody/ErrorBanner/ErrorBanner';
import { tableData } from './computed/tableData';
import { FLAG_ELIGIBILITY_INCORRECT } from 'src/store/actions/types';

export default {
  props: {
    insuranceType: {
      type: String,
    },
  },
  components: {
    DisplayIcon,
    ExpandedData,
    DetailsModalBody,
    ErrorBanner,
  },
  computed: {
    ...mapState({
      eligibilities: state => state.applicant.eligibilities.data,
      applicantVisits: state => state.applicant.visits.data,
      profiles: state => state.profiles.data,
    }),
    ...mapGetters(['applicant']),
    tableRows: {
      get: function() {
        return tableData.call(this);
      },
    },
  },
  methods: {
    ...mapActions([FLAG_ELIGIBILITY_INCORRECT]),
    expandCollapseAll() {
      this.expandAll = !this.expandAll;
      for (const item of this.tableRows) {
        this.$set(item, '_showDetails', this.expandAll);
      }
    },
    //TODO: refactor print to use an action/mutation
    async print(eligibilityId) {
      if (!this.printing) {
        this.printing = true;
        var pdf = await appService.getEligibilityPdf(eligibilityId);
        this.download('eligibility.pdf', pdf);
        this.printing = false;
      }
    },
    download(filename, text) {
      var pom = document.createElement('a');
      pom.setAttribute(
        'href',
        'data:application/pdf;base64,' + encodeURIComponent(text)
      );
      pom.setAttribute('download', filename);

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    },
    //modal methods
    details(data, button) {
      this.detailsModal.title = data.payorName + ' Information';
      this.detailsModal.content = data;
      this.$root.$emit('bv::show::modal', this.detailsModal.id, button);
    },
    resetDetailsModal() {
      this.detailsModal.title = '';
    },
    getVariant(incorrectHit) {
      return incorrectHit ? 'danger' : 'primary';
    },
    flagEligibility(eligibilityId, incorrectHit) {
      this[FLAG_ELIGIBILITY_INCORRECT]({ eligibilityId, incorrectHit });
    },
    async getExpandSetting() {
      var profile = this.profiles[0];
      if (profile && profile.expandOptions) {
        var insuranceOption = profile.expandOptions.filter(
          f => f.name === 'Insurance'
        );
        if (insuranceOption && insuranceOption[0]) {
          if (insuranceOption[0].expandAll === 'true') {
            for (const item of this.tableRows) {
              this.$set(item, '_showDetails', true);
            }
          } else if (insuranceOption[0].expandAll === 'false') {
            for (const item of this.tableRows) {
              this.$set(item, '_showDetails', false);
            }
          }
        }
      }
    },
  },
  async created() {
    await this.getExpandSetting();
  },
  data() {
    return {
      fields: [
        'ranking',
        'company_name',
        'msp',
        'warnings',
        'confidence',
        'status',
        'expand_all+',
      ],
      printing: false,
      detailsModal: {
        id: 'details-modal',
        title: '',
        content: {},
      },
      expandAll: false,
    };
  },
};
