import { displayRange } from 'src/utils/displayRange';
import { buildAddress } from 'src/utils/buildAddress';
import { dateStr } from 'src/utils/dateStr';
import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function generalPolicy() {
  return {
    label: 'General Policy Info',
    isExpanded: true,
    isDisabled: false,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      //The first array will hold all objects to go on left side
      //the second will hold any objects to go on the right side
      data: {
        left: {
          id: 'left-general',
          fields: stripEmptyValues([
            {
              label: 'Status',
              value: this.eligibility.status,
            },
            {
              label: 'Group Number',
              value: this.eligibility.groupNumber,
              canCopy: true,
            },
            {
              label: 'Subscriber Id',
              value: this.eligibility.subscriberId,
              canCopy: true,
            },
            {
              label: 'Prior Identification Number',
              value: this.eligibility.priorIdentificationNumber,
            },
            {
              label: 'Medicare Part A Start',
              value: this.eligibility.medicarePartAStart,
            },
            {
              label: 'Medicare Part A End',
              value: this.eligibility.medicarePartAEnd,
            },
            {
              label: 'Medicare Replacement Payor',
              value: this.eligibility.medicareReplacementPayor,
            },
            {
              label: 'Plan Sponsor',
              value: this.eligibility.planSponsor,
            },
            {
              label: 'County Code',
              value: this.eligibility.countyCode,
            },
            {
              label: 'Managed Care Plan',
              value: this.eligibility.managedCarePlan,
            },
            {
              label: 'Managed Care Subscriber Id',
              value: this.eligibility.managedCareSubscriberId,
            },
            {
              label: 'Managed Care Program',
              value: this.eligibility.managedCareProgram,
            },
            {
              label: 'Plan Network Id',
              value: this.eligibility.planNetworkIdentificationNumber,
            },
            {
              label: 'Plan Network Name',
              value: this.eligibility.ipaIdentifier
                ? this.eligibility.ipaIdentifier +
                  (this.eligibility.ipaDescription
                    ? ' (' + this.eligibility.ipaDescription + ')'
                    : '')
                : '',
            },
            {
              label: 'Provider Name',
              value: this.eligibility.providerName,
            },
            {
              label: 'HIC #',
              value: this.eligibility.hicNumber,
            },
            {
              label: 'Qualified Medicare Beneficiary (QMB)',
              value: this.eligibility.qualifiedMedicareBeneficiary,
              highlight:
                this.eligibility.qualifiedMedicareBeneficiary == 'Y'
                  ? true
                  : false,
            },
          ]),
        },
        right: {
          id: 'right-general',
          fields: stripEmptyValues([
            {
              label: 'Deductible Status',
              value: this.eligibility.deductibleManagement?.status,
            },
            {
              label: 'Policy Start Date',
              value: this.eligibility.policyStartDate,
            },
            {
              label: 'Policy End Date',
              value: this.eligibility.policyEndDate,
            },
            {
              label: 'Added Date',
              value: this.eligibility.addedDate,
            },
            {
              label: 'Electronic Verification Code',
              value: this.eligibility.electronicVerificationCode,
            },
            {
              label: 'Medicare Part B Start',
              value: this.eligibility.medicarePartBStart,
            },
            {
              label: 'Medicare Part B End',
              value: this.eligibility.medicarePartBEnd,
            },
            {
              label: 'COB Date',
              value: displayRange(
                this.eligibility.cobDate,
                this.eligibility.cobEndDate
              ),
            },
            {
              label: 'Medicare Replacement Plan',
              value: this.eligibility.medicareReplacementPlan,
            },
            {
              label: 'Medicaid Recipient Id',
              value: this.eligibility.medicaidRecipientId,
            },
            {
              label: 'Date Of Death',
              value: this.eligibility.dateOfDeath,
              highlight: true,
            },
            {
              label: 'Third Party Admin',
              value: this.eligibility.tpaName,
            },
            {
              label: 'Third Party Subscriber Id',
              value: this.eligibility.tpaSubscriberId,
            },
            {
              label: 'Please review record',
              value:
                this.eligibility.confidenceScore == 'R' &&
                !this.eligibility.incorrectHit
                  ? this.eligibility.confidenceScoreReason
                  : '',
            },
            {
              label: 'Additional Coverage',
              value: displayRange(
                this.eligibility.additionalCoverage,
                this.eligibility.additionalCoveragePolicyNumber
              ),
            },
            {
              label: 'Additional Coverage Start - End',
              value: displayRange(
                dateStr(this.eligibility.additionalCoverageStartDate),
                dateStr(this.eligibility.additionalCoverageEndDate)
              ),
            },
            {
              label: 'Additional Coverage Contact Number',
              value: this.eligibility.additionalCoverageContactNumber,
            },
            {
              label: 'Additional Coverage Address',
              value: buildAddress({
                address1: this.eligibility.additionalCoverageAddress,
                city: this.eligibility.additionalCoverageCity,
                state: this.eligibility.additionalCoverageState,
                zip: this.eligibility.additionalCoverageZip,
                address2: this.eligibility.additionalCoverageAddress2,
              }),
            },
          ]),
        },
      },
    },
  };
}
