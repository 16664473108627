import { benefitInfoNoServiceType } from './benefitConstants';
import { getDescriptions } from './getDescriptions';
import { getEbiObj } from './getEbiObj';
import { createListFromEbisMap } from './createListFromEbisMap';

export function getBenefitInformation(benefitInfoes) {
  if (!benefitInfoes) {
    return [];
  }
  var serviceTypeEbisMap = new Map();
  var previousDescriptions = {
    insuranceTypeDescription: '',
    planCoverageDescription: '',
    coverageLevelDescription: '',
  };
  for (const benefitInfo of benefitInfoes) {
    const ebi = getEbiObj(benefitInfo, previousDescriptions);
    previousDescriptions = getDescriptions(benefitInfo);
    if (benefitInfo.serviceTypeCodes.length) {
      for (const st of benefitInfo.serviceTypeCodes) {
        if (!serviceTypeEbisMap.has(st)) {
          serviceTypeEbisMap.set(st, { code: st, data: [] });
        }
        serviceTypeEbisMap.get(st).data.push(ebi);
      }
    } else {
      if (!serviceTypeEbisMap.has(benefitInfoNoServiceType)) {
        serviceTypeEbisMap.set(benefitInfoNoServiceType, {
          code: benefitInfoNoServiceType,
          data: [],
        });
      }
      serviceTypeEbisMap.get(benefitInfoNoServiceType).data.push(ebi);
    }
  }
  return createListFromEbisMap.call(this, serviceTypeEbisMap);
}
