import { mapActions } from 'vuex';
import MismatchItem from 'src/components/FullPatientData/PatientWidgetBody/MismatchItem/MismatchItem';
import Modal271 from 'src/components/Modal271/Modal271';
import {
  RUN_UPDATE_DEDUCTIBLE,
  GET_PAYOR_ELIGIBILITIES,
} from 'src/store/actions/types';

export default {
  props: {
    columns: {
      type: Object,
      required: true,
    },
  },
  components: {
    MismatchItem,
    'raw271-modal': Modal271,
  },
  methods: {
    ...mapActions([RUN_UPDATE_DEDUCTIBLE, GET_PAYOR_ELIGIBILITIES]),
    async runUpdateDeductibleAction(payorName) {
      await this[RUN_UPDATE_DEDUCTIBLE]({
        payorName: payorName,
      });
      this[GET_PAYOR_ELIGIBILITIES]();
    },
    async updateRN() {
      if (this.patientDataImports.length) {
        var json = {};
        for (var i = 0; i < this.patientDataImports.length; i++) {
          var item = this.patientDataImports[i];
          var updateType = item.type;
          var value = item.value;
          if (updateType === 'firstname') {
            json.firstName = value;
          }
          if (updateType === 'lastname') {
            json.lastName = value;
          }
          if (updateType === 'ssn') {
            json.ssn = value;
          }
          if (updateType === 'dob') {
            json.dob = value;
          }
          if (updateType === 'address1') {
            json.address1 = value;
          }
          if (updateType === 'city') {
            json.city = value;
          }
          if (updateType === 'state') {
            json.state = value;
          }
          if (updateType === 'zip') {
            json.zip = value;
          }
        }
        try {
          // eslint-disable-next-line
          await CefSharp.BindObjectAsync('cefSharpBoundAsync2', 'bound');
          // eslint-disable-next-line
          cefSharpBoundAsync2.showMessage(json);
        } catch (error) {
          this.displayErrorToast(
            'CefSharpLaunch Error: (most likely not running withn RN->PL app)' +
              error
          );
        }
      }
    },
  },
  data() {
    return {
      patientDataImports: [],
    };
  },
};
