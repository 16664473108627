import { getShouldBeDisabled } from './helpers/getShouldBeDisabled';
import { displayRange } from 'src/utils/displayRange';
import { stripEmptyValues } from 'src/utils/stripEmptyValues';

export function hospice() {
  return {
    label: 'Hospice',
    isExpanded: !getShouldBeDisabled(
      this.eligibility.hospiceNPI,
      this.eligibility.hospiceStartDate,
      this.eligibility.hospiceEndDate
    ),
    isDisabled: getShouldBeDisabled(
      this.eligibility.hospiceNPI,
      this.eligibility.hospiceStartDate,
      this.eligibility.hospiceEndDate
    ),
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DetailsModalBody/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        combined: {
          id: 'combined-hopsice',
          fields: stripEmptyValues([
            {
              label: 'Hospice NPI',
              value: this.eligibility.hospiceNPI,
            },
            {
              label: 'Hospice Dates',
              value: displayRange(
                this.eligibility.hospiceStartDate,
                this.eligibility.hospiceEndDate
              ),
            },
            {
              label: 'NPPES NPI Registry',
              value:
                'https://npiregistry.cms.hhs.gov/provider-view/' +
                this.eligibility.hospiceNPI,
              valueIsLink: true,
              target: '_blank',
            },
          ]),
        },
      },
    },
  };
}
