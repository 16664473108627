import { displayRange } from 'src/utils/displayRange';
import { getDurationDays } from './getDurationDays';
import { daysRemainingStr } from './daysRemainingStr';
import { dateStr } from 'src/utils/dateStr';
import { currencyStr } from 'src/utils/currencyStr';

export function getCopays(copayList) {
  if (!copayList) {
    return [];
  }
  return copayList.map(copay => ({
    admission_range: displayRange(
      dateStr(copay.admissionDateBegin),
      dateStr(copay.admissionDateEnd)
    ),
    duration: getDurationDays(copay.startDay, copay.endDay),
    copay: currencyStr(copay.amount),
    days_remaining: daysRemainingStr(copay.daysRemaining),
  }));
}
