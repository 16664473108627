/**
 * Takes a list of benefit infoes and returns up to the top two
 * plan names for any active coverage
 * @param {*} benefitInfoes
 * @returns
 */
export function getInsurancePlanNames(benefitInfoes) {
  var message = '';
  var applicableInfoes = benefitInfoes.filter(
    x =>
      x.infoTypeDescription === 'Active Coverage' && x.planCoverageDescription
  );

  if (applicableInfoes.length > 1) {
    message =
      applicableInfoes[0].planCoverageDescription +
      ', ' +
      applicableInfoes[1].planCoverageDescription;
  } else if (applicableInfoes.length == 1) {
    message = applicableInfoes[0].planCoverageDescription;
  }
  return message;
}
