import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import DiscoverySuggestion from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoverySuggestion/DiscoverySuggestion';
import Payor from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/Payor/Payor';
import DiscoveryTable from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoveryTable/DiscoveryTable';
import HistoryTable from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/HistoryTable/HistoryTable';
import ProductLastRunBadge from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/ProductLastRunBadge/ProductLastRunBadge';
import ErrorBanner from 'src/components/FullPatientData/PatientWidgetBody/ErrorBanner/ErrorBanner';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { mapState, mapGetters, mapActions } from 'vuex';
import { widgets } from 'src/constants/widgets';
import { permissionTypes } from 'src/constants/permissions';
import { insuranceTypes } from 'src/constants/insurance';
import {
  GET_PAYOR_ELIGIBILITIES,
  GET_ALL_PAYORS,
  VALIDATE_INSURANCE,
  GET_SERVICE_TYPES,
  RUN_INSURANCE_DISCOVERY,
  GET_INSURANCE_FINDER,
} from 'src/store/actions/types';
import { statuses } from 'src/constants/statuses';

// icon of the widget header
library.add(faFileInvoice);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    DiscoverySuggestion,
    Payor,
    DiscoveryTable,
    HistoryTable,
    ProductLastRunBadge,
    ErrorBanner
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission', 'isTriTech']),
    ...mapState({
      applicantVisits: state => state.applicant.visits.data,
      eligibilities: state => state.applicant.eligibilities.data,
      history: state => state.applicant.history.data,
      eligibilityErrors: state => state.applicant.eligibilities.errors,
      verifyErrors: state => state.applicant.validateInsurance.error,
      loadingEls: state => state.applicant.eligibilities.loading,
      retrievedEligibilities: state => state.applicant.eligibilities.retrieved,
      loadingIns: state => state.applicant.data.insuranceStatus,
      loadingDiscovery: state => state.applicant.insuranceDiscovery.status,
      idLastRunDate: state =>
        state.applicant.insuranceDiscovery.lastRunDate.data,
      idLastRunDateStatus: state =>
        state.applicant.insuranceDiscovery.lastRunDate.status,
      ranDiscovery: state => state.applicant.insuranceDiscovery.retrieved,
      retrievedInsuranceDiscovery: state =>
        state.applicant.insuranceDiscovery.retrieved,
      appInsurance: state => state.applicant?.data?.insurance,
      gurInsurance: state => state.guarantor?.data?.insurance,
      pcrInsurance: state => state.applicant?.epcrJsonData?.insurance_pcrpayors,
      allPayorsRetrieved: state => state.applicant.payors.retrieved,
      serviceTypesRetrieved: state => state.applicant.serviceTypes.retrieved,
      loading: state => !state.applicant.visits.retrieved,
      errorTooltip: function() {
        var eligibilityErroredPayors = '';
        if (this.eligibilityErrors?.length > 0) {
          var payors = [];
          for (var j = 0; j < this.eligibilityErrors.length; j++) {
            var payor = this.eligibilityErrors[j]?.payorName;
            if (!payors.includes(payor)) {
              payors.push(payor);
            }
          }
          for (var k = 0; k < payors.length; k++) {
            eligibilityErroredPayors += payors[k] + ', ';
          }
          eligibilityErroredPayors = eligibilityErroredPayors.substring(
            0,
            eligibilityErroredPayors.length - 2
          );
        } else {
          return '';
        }
        console.log('errored payor: ' + eligibilityErroredPayors);
        return (
          'Payors that could not be searched includes: ' +
          eligibilityErroredPayors
        );
      },
    }),

    loadingInsurance() {
      return (
        this.loadingDiscovery === statuses.pending ||
        this.validatingInsurance ||
        this.loadingIns === statuses.pending ||
        this.loadingEls ||
        this.idLastRunDateStatus === statuses.pending
      );
    },

    insurances: {
      get: function() {
        return [
          {
            name: 'Billing Insurance',
            payors: this.appInsurance,
            type: insuranceTypes.applicant,
          },
          {
            name: 'Guarantor Insurance',
            payors: this.gurInsurance,
            type: insuranceTypes.guarantor,
          },
          {
            name: 'PCR Insurance',
            payors: this.pcrInsurance,
            type: insuranceTypes.pcr,
          },
        ];
      },
    },
    //Checks to see if any un-mapped payors are present
    //necessary so we can pull all payors and present the legend
    hasUnmappedPayors: {
      get: function() {
        for (var i = 0; i < this.insurances?.length; i++) {
          var insurance = this.insurances[i];
          for (var j = 0; j < insurance?.payors?.length; j++) {
            if (insurance.payors[j].isMapped == false) {
              return true;
            }
          }
        }
        return false;
      },
    },

    // TODO: Refactor this to be more dynamic and use insurances.length
    hasPayors() {
      return (
        this.hasSpecificPayor(0) ||
        this.hasSpecificPayor(1) ||
        this.hasSpecificPayor(2)
      );
    },

    widgetType() {
      return widgets.insurance.type;
    },
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    canRunInsuranceVerifier() {
      return this.hasPermission(permissionTypes.canRunInsuranceVerifier);
    },
    canRunInsuranceDiscovery() {
      return this.hasPermission(permissionTypes.canRunInsuranceDiscovery);
    },
    canViewInsuranceWidget() {
      return this.hasPermission(permissionTypes.viewInsuranceWidget);
    },
    canFlagAsIncorrect() {
      return this.hasPermission(permissionTypes.canFlagAsIncorrect);
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
  },

  data() {
    return {
      checkedPayors: [],
      verifyDate: null,
      discoveryDate: null,
      validatingInsurance: false,
      //used to switch between the verify insurance screen
      discoveryScreen: false,
      idRanWithoutResults: false,
    };
  },
  methods: {
    ...mapActions([
      GET_PAYOR_ELIGIBILITIES,
      GET_ALL_PAYORS,
      GET_SERVICE_TYPES,
      VALIDATE_INSURANCE,
      RUN_INSURANCE_DISCOVERY,
      GET_INSURANCE_FINDER,
    ]),
    hasSpecificPayor(index) {
      return !!this.insurances[index]?.payors;
    },
    objEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    async runVerifyInsurance() {
      if (!this.discoveryScreen) {
        //switch to the discovery screen
        this.discoveryScreen = true;
        //do the verify
        //Remove this bool when VALIDATE_INSURANCE
        //is changed be done in batch
        this.validatingInsurance = true;
        await this.validateAllInsurance();
        this.validatingInsurance = false;
        //After insurance verifier has run
        //eligibility info is updated in the database
        this[GET_PAYOR_ELIGIBILITIES]();
        if (this.checkedPayors.length) {
          this.verifyDate = this.getDateStr();
        }
        if (this.eligibilities?.length == 0) {
          this.idRanWithoutResults = false;
        }
      } else {
        this.discoveryScreen = false;
      }
    },

    async validateAllInsurance() {
      await Promise.all(
        this.checkedPayors.map(async payor => {
          await this[VALIDATE_INSURANCE]({
            payorName: payor.payor,
            subscriberId: payor.policy,
            arrayIndex: payor.arrayIndex,
            insuranceType: payor.insuranceType,
          });
        })
      );
    },

    async runDiscoveryInsurance() {
      await this[RUN_INSURANCE_DISCOVERY]();
      this[GET_PAYOR_ELIGIBILITIES]();
      this.discoveryDate = this.getDateStr();
      this.discoveryScreen = true;
      if (this.eligibilities?.length == 0) {
        this.idRanWithoutResults = false;
      }
    },

    getDateStr() {
      return new Date().toLocaleString();
    },

    getLatestIVDate() {
      if (this.eligibilities?.length) {
        var latestRunDate = null;
        for (var i = 0; i < this.history?.length; i++) {
          var history = this.history[i];
          if (
            history &&
            history.createdByProduct != 'Realtime Insurance Finder' &&
            history.createdByProduct != 'MBI' &&
            history.createdByProduct != 'Retro Check'
          ) {
            if (!latestRunDate || latestRunDate < history.datetime) {
              latestRunDate = history.datetime;
            }
          }
        }
        return this.dateTimeStr(latestRunDate);
      }
    },
  },
  async created() {
    await this[GET_PAYOR_ELIGIBILITIES]();
    await this[GET_INSURANCE_FINDER]();
    //If we have any eligibilites we need to start
    //on the discovery table screen
    this.discoveryScreen = this.eligibilities?.length > 0 || this.idLastRunDate;
    if (this.idLastRunDate) {
      this.discoveryDate = this.dateTimeStr(this.idLastRunDate);
    }
    if (this.history && this.history.length > 0) {
      this.verifyDate = this.getLatestIVDate();
    }

    //if we have no eligibilities but have ran ID should produce an error
    if (this.eligibilities?.length == 0 && this.idLastRunDate) {
      this.idRanWithoutResults = true;
    }

    if (!this.allPayorsRetrieved) {
      this[GET_ALL_PAYORS]();
    }
    this[GET_SERVICE_TYPES]();
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
};
